import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import SectionContainer from "../../../../../../components/shared/section_container/section_container";
import SubTitle from "../../../../../../components/shared/sub-title/sub-title";
import TitleContainer from "../../../../../../components/shared/title_container/title_container";
import FormRow from "../../../../../../components/shared/form-row/form-row";
import StyleInput from "../../../../../../components/inputs/style-input";
import SSelect from "../../../../../../components/shared/s_select/s_select";
import TitleSection from "../../../../../components/title-section";
import ContentContainer from "../../../../../../components/shared/grid_content_container/grid_content_container";
import useTimeList, {
  HOUR_TYPE
} from "../../../../../../hooks/use_time_list/use_time_list";
import RadioContainer from "../../../../../../components/shared/radio_container/radio_container";
import { SInput } from "../../../../../../components/w_s_tabs/work_time_tab/choice_w_s/choice_basic_w_s/choice_basic_w_s";
import AsonicTable from "../../../../../../components/asonic-table/asonic-table";
import * as ReactTable from "react-table";
import {
  ButtonsContainer,
  Form,
  FormSection,
  TableSection
} from "../../../../../../components/alarm-push/styled";
import NativeSelect from "../../../../../../components/native-select";
import useConfirmDialog from "../../../../../../hooks/confirm-dialog-hook/use-confirm-dialog";
import useOpenToastMessage from "../../../../../../hooks/toast-message-hook/use-open-toast-message";
import useNativeSelect from "../../../../../../hooks/use-native-select";
import TextInputWithLabel from "../../../../../../components/inputs/text-input-with-label";
import { FieldValues, useForm } from "react-hook-form";
import Button from "../../../../../../components/globalComponents/Button";
import ConfirmDialog from "../../../../../../components/confirm-dialog/confirm-dialog";
import ToastMessage, {
  MessageTypes
} from "../../../../../../components/toast-message/toast-message";
import styled from "styled-components";
import { BUTTON_COLOR } from "../../../../../constants";
import {
  AlertSettingForWorkHourEntity,
  Setting_Enable_For_Working_Template,
  useAddAlertSettingForWorkHourMutation,
  useDeleteAlertSettingForWorkHourMutation,
  useGetAlertSettingForWorkHourLazyQuery,
  useGetListAllWorkingTemplateLazyQuery,
  useGetWtBasicInfoLazyQuery,
  useUpdateAlertSettingForWorkHourMutation
} from "../../../../../../generated/graphql";
import { WS_STATE } from "../../asonic-working-system.screen";
import DateManipulator from "../../../../../../brain/date-manipulator";
import { IAsonicRow } from "../../../../../../components/asonic-table/asonic-render-row";
import { ConfirmType, WorkHourTypeForTable } from "./constants";
import { LABELS, MESSAGES, TABLE_HEADER, TABLE_ROW, TITLES } from "./strings";

type NumOrStr = string | number;

interface Option {
  value: NumOrStr;
  name?: string;
}

const Container = styled.div`
  display: flex;
  gap: 20px;
`;

function WorkScheduleAdditionalSettingTab() {
  const wsState = useContext(WS_STATE);
  const { hourList, everyMins, hourTypeList } = useTimeList();

  const [amPmOfStartTimeOfDay, setAmPmOfStartTimeOfDay] = useState<string>(
    HOUR_TYPE.AM
  ); // 하루 시작 시간 (오전/오후)
  const [amPmOfEndTimeOfDay, setAmPmOfEndTimeOfDay] = useState<string>(
    HOUR_TYPE.AM
  ); // 하루 종료 시간 (오전/오후)
  const [hourOfStartTimeOfDay, setHourOfStartTimeOfDay] = useState<number>(6); // 하루 시작 시간 (시)
  const [hourOfEndTimeOfDay, setHourOfEndTimeOfDay] = useState<number>(5); // 하루 종료 시간 (시)
  const [minOfStartTimeOfDay, setMinOfStartTimeOfDay] = useState<number>(0); // 하루 시작 시간 (분)
  const [minOfEndTimeOfDay, setMinOfEndTimeOfDay] = useState<number>(59); // 하루 종료 시간 (분)
  const [nightAmPmOfStartTime, setNightAmPmOfStartTime] = useState<string>(
    HOUR_TYPE.PM
  ); // 야간 시작 시간 (오전/오후)
  const [nightAmPmOfEndTime, setNightAmPmOfEndTime] = useState<string>(
    HOUR_TYPE.AM
  ); // 야간 종료 시간 (오전/오후)
  const [nightHourOfStartTime, setNightHourOfStartTime] = useState<number>(10); // 야간 시작 시간 (시)
  const [nightHourOfEndTime, setNightHourOfEndTime] = useState<number>(5); // 야간 종료 시간 (시)
  const [nightMinOfStartTime, setNightMinOfStartTime] = useState<number>(0); // 야간 시작 시간 (분)
  const [nightMinOfEndTime, setNightMinOfEndTime] = useState<number>(59); // 야간 종료 시간 (분)

  const [adminInputType, setAdminInputType] =
    useState<Setting_Enable_For_Working_Template>(
      Setting_Enable_For_Working_Template.NoUse
    ); // 월 단위 근무 시간 (자동계산, 관리자 입력)

  const [adminInputHour, setAdminInputHour] = useState<number>(0); // 관리자 입력 시간 (시)
  const [adminInputMin, setAdminInputMin] = useState<number>(0); // 관리자 입력 시간 (분)

  const [weeklyHolidayAllowanceType, setWeeklyHolidayAllowanceType] =
    useState<Setting_Enable_For_Working_Template>(
      Setting_Enable_For_Working_Template.NoUse
    ); // 주당 휴일 사용 여부

  const {
    confirmTitle,
    confirmParagraph,
    isOpen,
    handleIsOpen,
    handleConfirmMessage,
    confirmType
  } = useConfirmDialog();

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const { selectedItem, handleSelectedItem, setSelectedItem } = useNativeSelect(
    WorkHourTypeForTable.DAY
  );

  const [confirmTypeOfButton, setConfirmTypeOfButton] = useState<
    ConfirmType | undefined
  >(undefined);

  const { register, getValues, setValue } = useForm<FieldValues>({
    mode: "onSubmit",
    defaultValues: {
      pushMin: "0"
    }
  });

  const renderSSelect = (
    id: string,
    minWidth: string,
    maxWidth: string,
    value: string | number,
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void,
    options: Option[] | string[]
  ) => (
    <SSelect
      id={id}
      minWidth={minWidth}
      maxWidth={maxWidth}
      value={value}
      onChange={onChange}
    >
      {options.map(item => {
        const option = item as Option;
        const optionValue = option.value === undefined ? item : option.value;
        const optionName = option.name === undefined ? item : option.name;
        return (
          <option value={optionValue as NumOrStr} key={optionValue as NumOrStr}>
            {optionName}
          </option>
        );
      })}
    </SSelect>
  );

  // 미성년자 근무표
  const [selectedWorkingTemplateIndex, setSelectedWorkingTemplateIndex] =
    useState<number>(0);
  const [getListWorkingTemplate, { data: workingTemplateData }] =
    useGetListAllWorkingTemplateLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  useEffect(() => {
    getListWorkingTemplate({
      variables: {
        searchValue: ""
      }
    });
  }, [getListWorkingTemplate]);
  ///////////////////////////
  // 기본정보, 추가정보
  const [getWtBasicInfo, { data: workHourData }] = useGetWtBasicInfoLazyQuery({
    onError(error) {
      console.log(error);
    }
  });

  useEffect(() => {
    if (wsState?.workingTemplateIdx) {
      getWtBasicInfo({
        variables: {
          workingTemplateIdx: wsState.workingTemplateIdx
        }
      });
    }
  }, [getWtBasicInfo, wsState?.workingTemplateIdx]);

  useEffect(() => {
    if (workHourData?.getWTBasicInfo?.wTBasicInfo) {
      const {
        standardDailyStartTimeRange, // 하루 시작 시간
        standardDailyEndTimeRange, // 하루 종료 시간
        nightReferenceStartTimeRange, // 야간 시작 시간
        nightReferenceEndTimeRange, // 야간 종료 시간
        periodUnitMonthFixMin, // 관리자 입력일 때 분
        periodUnitMonthMinType, // 월 단위 근무 시간 (자동계산, 관리자 입력)
        weeklyHolidayAllowanceUse, // 주당 휴일 사용 여부
        workingTemplateIdxMinors // 미성년자 근무 템플릿 인덱스
      } = workHourData.getWTBasicInfo.wTBasicInfo;

      const dmp = new DateManipulator();
      const convertedStandardDailyStartTimeRange =
        dmp.getConvertedTimeFromParam(standardDailyStartTimeRange, "06:00:00");
      const convertedStandardDailyEndTimeRange = dmp.getConvertedTimeFromParam(
        standardDailyEndTimeRange,
        "05:59:00"
      );
      const convertedNightReferenceStartTimeRange =
        dmp.getConvertedTimeFromParam(nightReferenceStartTimeRange, "22:00:00");
      const convertedNightReferenceEndTimeRange = dmp.getConvertedTimeFromParam(
        nightReferenceEndTimeRange,
        "05:59:59"
      );
      setAmPmOfStartTimeOfDay(
        convertedStandardDailyStartTimeRange.isAM ? HOUR_TYPE.AM : HOUR_TYPE.PM
      );
      setAmPmOfEndTimeOfDay(
        convertedStandardDailyEndTimeRange.isAM ? HOUR_TYPE.AM : HOUR_TYPE.PM
      );
      setHourOfStartTimeOfDay(convertedStandardDailyStartTimeRange.hour);
      setMinOfStartTimeOfDay(convertedStandardDailyStartTimeRange.minute);
      setHourOfEndTimeOfDay(convertedStandardDailyEndTimeRange.hour);
      setMinOfEndTimeOfDay(convertedStandardDailyEndTimeRange.minute);

      setNightAmPmOfStartTime(
        convertedNightReferenceStartTimeRange.isAM ? HOUR_TYPE.AM : HOUR_TYPE.PM
      );
      setNightAmPmOfEndTime(
        convertedNightReferenceEndTimeRange.isAM ? HOUR_TYPE.AM : HOUR_TYPE.PM
      );
      setNightHourOfStartTime(convertedNightReferenceStartTimeRange.hour);
      setNightMinOfStartTime(convertedNightReferenceStartTimeRange.minute);
      setNightHourOfEndTime(convertedNightReferenceEndTimeRange.hour);
      setNightMinOfEndTime(convertedNightReferenceEndTimeRange.minute);

      const { hour, minute } = dmp.convertMinToHourAndMin(
        periodUnitMonthFixMin
      );
      setAdminInputHour(hour);
      setAdminInputMin(minute);
      setAdminInputType(periodUnitMonthMinType);

      setWeeklyHolidayAllowanceType(weeklyHolidayAllowanceUse);
      setSelectedWorkingTemplateIndex(workingTemplateIdxMinors ?? 0);
    }
  }, [workHourData?.getWTBasicInfo?.wTBasicInfo]);
  //////////////////////////////

  // 기본설정 근무 시간 기준 알람 설정
  const [getAlertSettingForWorkHour, { data: alertSettingData }] =
    useGetAlertSettingForWorkHourLazyQuery({
      onError(error) {
        console.log(error);
      }
    });

  const [addAlertSettingForWorkHour, { client }] =
    useAddAlertSettingForWorkHourMutation({
      onError(error) {
        console.log(error);
        handleToast(MESSAGES.ADD_ALARM_ERROR, MessageTypes.ERROR);
        handleIsOpen(false);
      },
      update(_, { data }) {
        if (data?.addAlertSettingForWorkHour.ok) {
          client.resetStore();
          handleToast(MESSAGES.ADD_ALARM_SUCCESS, MessageTypes.SUCCESS);
        } else if (data?.addAlertSettingForWorkHour.error) {
          handleToast(
            data?.addAlertSettingForWorkHour.error,
            MessageTypes.ERROR
          );
        }
        handleIsOpen(false);
      }
    });

  const [updateAlertSettingForWorkHour] =
    useUpdateAlertSettingForWorkHourMutation({
      onError(error) {
        console.log(error);
        handleToast(MESSAGES.EDIT_ALARM_ERROR, MessageTypes.ERROR);
        handleIsOpen(false);
      },
      update(_, { data }) {
        if (data?.updateAlertSettingForWorkHour.ok) {
          client.resetStore();
          handleToast(MESSAGES.EDIT_ALARM_SUCCESS, MessageTypes.SUCCESS);
        } else if (data?.updateAlertSettingForWorkHour.error) {
          handleToast(
            data?.updateAlertSettingForWorkHour.error,
            MessageTypes.ERROR
          );
        }
        handleIsOpen(false);
      }
    });

  const [deleteAlertSettingForWorkHour] =
    useDeleteAlertSettingForWorkHourMutation({
      onError(error) {
        console.log(error);
        handleToast(MESSAGES.DELETE_ALARM_ERROR, MessageTypes.ERROR);
        handleIsOpen(false);
      },
      update(_, { data }) {
        if (data?.deleteAlertSettingForWorkHour.ok) {
          client.resetStore();
          handleToast(
            MESSAGES.DELETE_ALARM_EVENT_CONFIRM,
            MessageTypes.SUCCESS
          );
        } else if (data?.deleteAlertSettingForWorkHour.error) {
          handleToast(
            data?.deleteAlertSettingForWorkHour.error,
            MessageTypes.ERROR
          );
        }
        handleIsOpen(false);
      }
    });

  useEffect(() => {
    if (wsState?.workingTemplateIdx) {
      getAlertSettingForWorkHour({
        variables: {
          workingTemplateIdx: wsState.workingTemplateIdx,
          settingIndices: []
        }
      });
    }
  }, [getAlertSettingForWorkHour, wsState?.workingTemplateIdx]);
  //////////////////////////////////

  useEffect(() => {
    const dmp = new DateManipulator();

    wsState?.handleWorkPolicyAdditionalSetting({
      nightReferenceStartTimeRange: dmp.revertBackTimeFormat(
        nightAmPmOfStartTime,
        nightHourOfStartTime,
        nightMinOfStartTime,
        0
      ),
      nightReferenceEndTimeRange: dmp.revertBackTimeFormat(
        nightAmPmOfEndTime,
        nightHourOfEndTime,
        nightMinOfEndTime,
        0
      ),
      periodUnitMonthMinType: adminInputType,
      periodUnitMonthFixMin: dmp.revertBackToMin(adminInputHour, adminInputMin),
      standardDailyStartTimeRange: dmp.revertBackTimeFormat(
        amPmOfStartTimeOfDay,
        hourOfStartTimeOfDay,
        minOfStartTimeOfDay,
        0
      ),
      standardDailyEndTimeRange: dmp.revertBackTimeFormat(
        amPmOfEndTimeOfDay,
        hourOfEndTimeOfDay,
        minOfEndTimeOfDay,
        0
      ),
      weeklyHolidayAllowanceUse: weeklyHolidayAllowanceType,
      workingTemplateIdxMinors: selectedWorkingTemplateIndex
    });
  }, [
    amPmOfStartTimeOfDay,
    amPmOfEndTimeOfDay,
    hourOfStartTimeOfDay,
    hourOfEndTimeOfDay,
    minOfStartTimeOfDay,
    minOfEndTimeOfDay,
    nightAmPmOfStartTime,
    nightAmPmOfEndTime,
    nightHourOfStartTime,
    nightHourOfEndTime,
    nightMinOfStartTime,
    nightMinOfEndTime,
    adminInputType,
    adminInputHour,
    adminInputMin,
    weeklyHolidayAllowanceType,
    selectedWorkingTemplateIndex
  ]);

  const columns: ReactTable.Column<AlertSettingForWorkHourEntity>[] =
    useMemo(() => {
      const width = 180;
      return [
        {
          Header: TABLE_HEADER.ALARM_TYPE,
          accessor(data) {
            return data.eventType === WorkHourTypeForTable.DAY
              ? TABLE_ROW.DAY_WORK_HOUR
              : TABLE_ROW.WEEK_WORK_HOUR;
          },
          width
        },
        {
          Header: TABLE_HEADER.ALARM_TIME,
          accessor: (data: AlertSettingForWorkHourEntity) => data.pushMin,
          width
        },
        {
          Header: TABLE_HEADER.TITLE,
          accessor: (data: AlertSettingForWorkHourEntity) => data.title,
          width
        },
        {
          Header: TABLE_HEADER.CONTENT,
          accessor: (data: AlertSettingForWorkHourEntity) => data.descr,
          width
        }
      ];
    }, [alertSettingData]);

  const alertData = useMemo(() => {
    return alertSettingData?.getAlertSettingForWorkHour.list ?? [];
  }, [alertSettingData]);

  const {
    prepareRow,
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    selectedFlatRows
  } = ReactTable.useTable<AlertSettingForWorkHourEntity>(
    {
      columns,
      data: alertData
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder
  );

  const workingTemplateOptions = useMemo(() => {
    return [
      { value: 0, name: LABELS.NOT_USE_KO },
      ...(workingTemplateData?.getListAllWorkingTemplate?.list?.map(item => ({
        value: item.workingTemplateIdx,
        name: item.name
      })) ?? [])
    ];
  }, [workingTemplateData]);

  // 현재 선택된 row를 넘겨줘야 테이블이 여러 row를 표시하지 않는다
  const selectedRow: ReactTable.Row<AlertSettingForWorkHourEntity> | undefined =
    useMemo(() => {
      if (selectedFlatRows.length > 0) {
        return selectedFlatRows[selectedFlatRows.length - 1];
      }
      return;
    }, [selectedFlatRows]);

  const handleSelectRow = useCallback(
    (row?: IAsonicRow<AlertSettingForWorkHourEntity>) => {
      if (typeof row?.original.pushMin === "number") {
        setValue("pushMin", row.original.pushMin.toString());
      }
      if (row?.original.title) {
        setValue("title", row.original.title);
      }
      if (row?.original.descr) {
        setValue("description", row.original.descr);
      }
      if (row?.original.eventType) {
        setSelectedItem(
          row.original.eventType === 1
            ? WorkHourTypeForTable.DAY
            : WorkHourTypeForTable.WEEK
        );
      }
    },
    [selectedRow]
  );

  return (
    <SectionContainer>
      <TitleSection>
        <TitleContainer>
          <SubTitle title={TITLES.ADDITIONAL_SETTINGS} />
        </TitleContainer>
      </TitleSection>
      <ContentContainer flex={0}>
        <FormRow title={TITLES.WORK_SCHEDULE} gridNumber={1}>
          {renderSSelect(
            "underAgeWorkSchedule",
            "186px",
            "186px",
            selectedWorkingTemplateIndex,
            event => {
              setSelectedWorkingTemplateIndex(Number(event.target.value));
            },
            workingTemplateOptions as Option[]
          )}
        </FormRow>
        <FormRow title={TITLES.START_TIME}>
          {renderSSelect(
            "startTimeType",
            "60px",
            "60px",
            amPmOfStartTimeOfDay,
            event => {
              setAmPmOfStartTimeOfDay(event.target.value);
            },
            hourTypeList
          )}
          {renderSSelect(
            "startTimeHour",
            "50px",
            "50px",
            hourOfStartTimeOfDay,
            event => {
              setHourOfStartTimeOfDay(Number(event.target.value));
            },
            hourList
          )}
          <label htmlFor="startTimeHour">{LABELS.HOUR}</label>
          {renderSSelect(
            "startTimeMin",
            "50px",
            "50px",
            minOfStartTimeOfDay,
            event => {
              setMinOfStartTimeOfDay(Number(event.target.value));
            },
            everyMins as Option[]
          )}
          <label htmlFor="startTimeMin">{LABELS.MINUTE}</label>
        </FormRow>
        <FormRow title={TITLES.END_TIME}>
          {renderSSelect(
            "endTimeType",
            "60px",
            "60px",
            amPmOfEndTimeOfDay,
            event => {
              setAmPmOfEndTimeOfDay(event.target.value);
            },
            hourTypeList
          )}
          {renderSSelect(
            "endTimeHour",
            "50px",
            "50px",
            hourOfEndTimeOfDay,
            event => {
              setHourOfEndTimeOfDay(Number(event.target.value));
            },
            hourList
          )}
          <label htmlFor="endTimeHour">{LABELS.HOUR}</label>
          {renderSSelect(
            "endTimeMin",
            "50px",
            "50px",
            minOfEndTimeOfDay,
            event => {
              setMinOfEndTimeOfDay(Number(event.target.value));
            },
            everyMins as Option[]
          )}
          <label htmlFor="endTimeMin">{LABELS.MINUTE}</label>
        </FormRow>
        <FormRow title={TITLES.NIGHT_START_TIME}>
          {renderSSelect(
            "nightStartTimeType",
            "60px",
            "60px",
            nightAmPmOfStartTime,
            event => {
              setNightAmPmOfStartTime(event.target.value);
            },
            hourTypeList
          )}
          {renderSSelect(
            "nightStartTimeHour",
            "50px",
            "50px",
            nightHourOfStartTime,
            event => {
              setNightHourOfStartTime(Number(event.target.value));
            },
            hourList
          )}
          <label htmlFor="nightStartTimeHour">{LABELS.HOUR}</label>
          {renderSSelect(
            "nightStartTimeMin",
            "50px",
            "50px",
            nightMinOfStartTime,
            event => {
              setNightMinOfStartTime(Number(event.target.value));
            },
            everyMins as Option[]
          )}
          <label htmlFor="nightStartTimeMin">{LABELS.MINUTE}</label>
        </FormRow>
        <FormRow title={TITLES.NIGHT_END_TIME}>
          {renderSSelect(
            "nightEndTimeType",
            "60px",
            "60px",
            nightAmPmOfEndTime,
            event => {
              setNightAmPmOfEndTime(event.target.value);
            },
            hourTypeList
          )}
          {renderSSelect(
            "nightEndTimeHour",
            "50px",
            "50px",
            nightHourOfEndTime,
            event => {
              setNightHourOfEndTime(Number(event.target.value));
            },
            hourList
          )}
          <label htmlFor="nightEndTimeHour">{LABELS.HOUR}</label>
          {renderSSelect(
            "nightEndTimeMin",
            "50px",
            "50px",
            nightMinOfEndTime,
            event => {
              setNightMinOfEndTime(Number(event.target.value));
            },
            everyMins as Option[]
          )}
          <label htmlFor="nightEndTimeMin">{LABELS.MINUTE}</label>
        </FormRow>
        <FormRow title={TITLES.MONTHLY_WORK_HOURS}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="self"
              minWidth="15px"
              maxWidth="15px"
              name="adminInputType"
              checked={
                adminInputType === Setting_Enable_For_Working_Template.NoUse
              }
              onChange={event => {
                setAdminInputType(
                  event.target.checked
                    ? Setting_Enable_For_Working_Template.NoUse
                    : Setting_Enable_For_Working_Template.Use
                );
              }}
            />
            <label>{LABELS.AUTO_CALCULATION}</label>
            <StyleInput
              type="radio"
              id="self"
              minWidth="15px"
              maxWidth="15px"
              name="adminInputType"
              checked={
                adminInputType === Setting_Enable_For_Working_Template.Use
              }
              onChange={event => {
                setAdminInputType(
                  event.target.checked
                    ? Setting_Enable_For_Working_Template.Use
                    : Setting_Enable_For_Working_Template.NoUse
                );
              }}
              style={{ marginLeft: "30px" }} // 왼쪽에 10px 간격 추가
            />
            <label>{LABELS.ADMIN_INPUT}</label>
            <SInput
              type="number"
              name="adminInputHour"
              id="adminInputHour"
              value={adminInputHour}
              onChange={event => {
                setAdminInputHour(Number(event.target.value));
              }}
              max={24}
              min={0}
              disabled={
                adminInputType === Setting_Enable_For_Working_Template.NoUse
              }
            />
            <label htmlFor="adminInputHour">{LABELS.HOUR_TIME}</label>
            <SInput
              type="number"
              name="adminInputMin"
              id="adminInputMin"
              value={adminInputMin}
              onChange={event => {
                setAdminInputMin(Number(event.target.value));
              }}
              max={59}
              min={0}
              disabled={
                adminInputType === Setting_Enable_For_Working_Template.NoUse
              }
            />
            <label htmlFor="adminInputMin">{LABELS.MINUTE}</label>
          </RadioContainer>
        </FormRow>
        <FormRow title={TITLES.WEEKLY_HOLIDAY_ALLOWANCE}>
          <RadioContainer>
            <StyleInput
              type="radio"
              id="self"
              minWidth="15px"
              maxWidth="15px"
              name="weeklyHolidayAllowance"
              checked={
                weeklyHolidayAllowanceType ===
                Setting_Enable_For_Working_Template.NoUse
              }
              onChange={event => {
                setWeeklyHolidayAllowanceType(
                  event.target.checked
                    ? Setting_Enable_For_Working_Template.NoUse
                    : Setting_Enable_For_Working_Template.Use
                );
              }}
            />
            <label>{LABELS.NOT_USE}</label>
            <StyleInput
              type="radio"
              id="self"
              minWidth="15px"
              maxWidth="15px"
              name="weeklyHolidayAllowance"
              checked={
                weeklyHolidayAllowanceType ===
                Setting_Enable_For_Working_Template.Use
              }
              onChange={event => {
                setWeeklyHolidayAllowanceType(
                  event.target.checked
                    ? Setting_Enable_For_Working_Template.Use
                    : Setting_Enable_For_Working_Template.NoUse
                );
              }}
            />
            <label>{LABELS.USE}</label>
          </RadioContainer>
        </FormRow>
      </ContentContainer>
      {wsState?.workingTemplateIdx && (
        <Container>
          <TableSection>
            <TitleSection>
              <TitleContainer>
                <SubTitle title={TITLES.ALARM_SETTINGS} />
              </TitleContainer>
            </TitleSection>
            <AsonicTable
              title={TITLES.ALARM_SETTINGS}
              handleSelectRow={handleSelectRow}
              isLoading={false}
              prepareRow={prepareRow}
              getTableProps={getTableProps}
              headerGroups={headerGroups}
              getTableBodyProps={getTableBodyProps}
              rows={rows}
              selectedRow={selectedRow}
              isTitleBar={false}
            />
          </TableSection>
          <Form>
            <SectionContainer>
              <FormSection>
                <NativeSelect
                  title={TABLE_HEADER.ALARM_TYPE}
                  propFor="alarmType"
                  value={selectedItem}
                  datas={[
                    {
                      title: TABLE_ROW.DAY_WORK_HOUR,
                      value: WorkHourTypeForTable.DAY
                    },
                    {
                      title: TABLE_ROW.WEEK_WORK_HOUR,
                      value: WorkHourTypeForTable.WEEK
                    }
                  ]}
                  handleSelectedItem={handleSelectedItem}
                />
              </FormSection>
              <FormSection>
                <TextInputWithLabel
                  id="pushMin"
                  title={TABLE_HEADER.ALARM_TIME}
                  {...register("pushMin", { required: true })}
                  type="number"
                  required={true}
                />
              </FormSection>
              <FormSection>
                <TextInputWithLabel
                  id="title"
                  title={TABLE_HEADER.TITLE}
                  {...register("title", { required: true })}
                  required={true}
                />
              </FormSection>
              <FormSection>
                <TextInputWithLabel
                  id="description"
                  title={TABLE_HEADER.CONTENT}
                  {...register("description")}
                />
              </FormSection>
              <ButtonsContainer>
                <Button
                  name={ConfirmType.ADD}
                  onClick={_ => {
                    setConfirmTypeOfButton(ConfirmType.ADD);
                    handleConfirmMessage({
                      title: MESSAGES.ADD_ALARM_EVENT,
                      p: MESSAGES.ADD_ALARM_EVENT_CONFIRM,
                      messageTypes: MessageTypes.WARNING
                    });
                    handleIsOpen(true);
                  }}
                  backgroundColor={BUTTON_COLOR.ADD}
                  type="button"
                >
                  추가
                </Button>
                <Button
                  name={ConfirmType.UPDATE}
                  onClick={_ => {
                    setConfirmTypeOfButton(ConfirmType.UPDATE);
                    handleConfirmMessage({
                      title: MESSAGES.EDIT_ALARM_EVENT,
                      p: MESSAGES.EDIT_ALARM_EVENT_CONFIRM,
                      messageTypes: MessageTypes.WARNING
                    });
                    handleIsOpen(true);
                  }}
                  backgroundColor={BUTTON_COLOR.EDIT}
                  disabled={!selectedRow}
                  type="button"
                >
                  수정
                </Button>
                <Button
                  name={ConfirmType.DELETE}
                  onClick={_ => {
                    setConfirmTypeOfButton(ConfirmType.DELETE);
                    handleConfirmMessage({
                      title: MESSAGES.DELETE_ALARM_EVENT,
                      p: MESSAGES.DELETE_ALARM_EVENT_CONFIRM,
                      messageTypes: MessageTypes.WARNING
                    });
                    handleIsOpen(true);
                  }}
                  backgroundColor={BUTTON_COLOR.DELETE}
                  disabled={!selectedRow}
                  type="button"
                >
                  삭제
                </Button>
              </ButtonsContainer>
            </SectionContainer>
          </Form>
          {isOpen && (
            <ConfirmDialog
              confirmTitle={confirmTitle}
              confirmParagraph={confirmParagraph}
              confirmType={confirmType}
              messageTypes={MessageTypes.SUCCESS}
              handleIsOpen={handleIsOpen}
              handleConfirm={event => {
                if (!wsState?.workingTemplateIdx) return;

                if (confirmTypeOfButton === ConfirmType.ADD) {
                  addAlertSettingForWorkHour({
                    variables: {
                      workingTemplateIdx: wsState.workingTemplateIdx,
                      eventType: selectedItem,
                      pushMin: Number(getValues("pushMin")),
                      title: getValues("title"),
                      descr: getValues("description")
                    }
                  });
                } else if (
                  confirmTypeOfButton === ConfirmType.UPDATE &&
                  selectedRow
                ) {
                  updateAlertSettingForWorkHour({
                    variables: {
                      workingTemplateIdx: wsState.workingTemplateIdx,
                      eventType:
                        selectedItem === WorkHourTypeForTable.DAY
                          ? WorkHourTypeForTable.DAY
                          : WorkHourTypeForTable.WEEK,
                      pushMin: Number(getValues("pushMin")),
                      title: getValues("title"),
                      descr: getValues("description"),
                      settingIndex: selectedRow.original.settingIndex
                    }
                  });
                } else if (
                  confirmTypeOfButton === ConfirmType.DELETE &&
                  selectedRow
                ) {
                  deleteAlertSettingForWorkHour({
                    variables: {
                      workingTemplateIdx: wsState.workingTemplateIdx,
                      settingIndices: [selectedRow.original.settingIndex]
                    }
                  });
                }
              }}
            />
          )}
          <ToastMessage
            message={message}
            isOpen={isToastMessageOpen}
            handleIsOpen={handleIsToastMessageOpen}
            messageTypes={toastMessageType}
          />
        </Container>
      )}
    </SectionContainer>
  );
}

export default WorkScheduleAdditionalSettingTab;
