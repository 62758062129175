import styled from "styled-components";
import { Suspense, useCallback, useEffect, useMemo, useState } from "react";
import Spinner from "../../../../../components/shared/spinner";
import * as ReactTable from "react-table";
import moment from "moment";
import usePageControl from "../../../../../hooks/use-page-control/use-page-control";
import { useReactiveVar } from "@apollo/client";
import listOfApolloVar from "../../../../../apollo/apollo-var";
import { TColumn } from "../../../../../hooks/use-hide-columns/use-hide-columns";
import PageController from "../../../../../components/table/page_controller";
import ButtonContainer from "../../../../../components/shared/button_container";
import {
  UserWeeklyWorkPlanEntity,
  useGetUserWeeklyWorkExcelPlanLazyQuery,
  useGetUserWeeklyWorkPlanCountLazyQuery,
  useGetUserWeeklyWorkPlanLazyQuery
} from "../../../../../generated/graphql";
import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";
import StyledInput from "../../../../../components/shared/styled_input";
import { Cell } from "../../../../../../types/@react-table/react-table/react-table";
import ToastMessage, {
  MessageTypes
} from "../../../../../components/toast-message/toast-message";
import useOpenToastMessage from "../../../../../hooks/toast-message-hook/use-open-toast-message";
import { downloadFileFromServer } from "../../../../../components/main-view/statistics/Utils";
import TableV2 from "../../../../../components/table_v2/table_v2";
import { AutoSizer } from "react-virtualized";
import useFixedColumn from "../../../../../hooks/use_fixed_column/use_fixed_column";
import { useSticky } from "react-table-sticky";
import { useSearchDateRange } from "../../../../../components/main-view/WorkManagementInfo/hooks";
import { TABS_DB_SCHEME_OBJECT } from "../../../../../components/main-view/WorkManagementInfo/logics";
import UserWeeklyWorkHeader from "./user-weekly-work-header";

// 컬럼이 25개가 넘어가면 type error 난다. 왜 그런지 모름 ㅠㅠ
const COLUMN_FOR_VALUE = {
  isCheck: "선택",
  departmentName: "부서",
  employeeId: "사번(ERP)",
  employeeName: "이름(ERP)",
  monthStartDay: "시작날짜",
  monthLastDay: "종료날짜",
  //   restPlanMin: "휴게시간(계획)",
  //   recognitionPlanTime: "근무시간(계획)",
  restAdmitMin: "휴게시간(인정)",
  recognitionAdmitTime: "근무시간(인정)",
  //   payTime: "급여시간",
  //   monthWeekNumberCustomYearMonth: "월(month)",
  monthWeekNumberCustom: "주(week)",
  salaryTypeName: "급여제",
  hourlyPay: "시급",
  weekdayBasicMin: "기본수당(시간)",
  weekdayBasicMinWon: "기본수당(급여)",
  weekdayExtMin: "연장수당(시간)",
  weekdayExtMinWon: "연장수당(급여)",
  weekdayNightMin: "야간수당(시간)",
  weekdayNightMinWon: "야간수당(급여)",
  holidayBasicMin: "휴일수당(시간)",
  holidayBasicMinWon: "휴일수당(급여)",
  holidayExtDayUintMin: "휴일연장수당(시간)",
  holidayExtDayUintMinWon: "휴일연장수당(급여)",
  holidayNightMin: "휴일야간수당(시간)",
  holidayNightMinWon: "휴일야간수당(급여)",
  weeklyRestMin: "주휴수당(시간)",
  weeklyRestMinWon: "주휴수당(급여)"
} as const;

type WEEKLY_WORK_TYPE = keyof typeof COLUMN_FOR_VALUE;

export interface IUserWeeklyWorkPlanEntity
  extends Partial<UserWeeklyWorkPlanEntity> {
  isCheck?: boolean;
}

interface IProps {
  isVisibility: boolean;
}

const Container = styled.div<{ $isVisibility: boolean }>`
  display: flex;
  flex: ${props => (props.$isVisibility ? "1" : "0")};
  overflow-x: hidden;
  flex-direction: column;
  visibility: ${props => (props.$isVisibility ? "visible" : "hidden")};
`;

const TableContainer = styled.div`
  display: flex;
  flex: 10;
  will-change: scroll-position;
`;

const TableFooter = styled.div`
  display: flex;
  flex: 1;
`;

const CheckBoxContainer = styled(StyledFlexContainer)`
  justify-content: center;
  gap: 0.5em;
`;

const BtnContainer = styled(ButtonContainer)`
  padding: 10px;
`;

const indexDBTabName = "근무시간정보-주별근무";
let prevEmployeeIdList: string[] = [];

function UserWeeklyWork({ isVisibility }: IProps) {
  const {
    fixedColumnNumber,
    selectedFixedColumnNumber,
    handleSelectedFCN,
    sFixedColumnNumber
  } = useFixedColumn();
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    saveSearchDate,
    retryRender,
    setRetryRender
  } = useSearchDateRange(TABS_DB_SCHEME_OBJECT.workHourWeekly, indexDBTabName);

  const { currentPage, handleCurrentPage, take, handleTake } = usePageControl();
  const selectedListOfEmployeeId = useReactiveVar(
    listOfApolloVar.selectedListOfEmployeeIdVar
  );

  const {
    isOpen: isToastMessageOpen,
    handleIsOpen: handleIsToastMessageOpen,
    message,
    toastMessageType,
    handleToast
  } = useOpenToastMessage();

  const [getUserWeeklyWorkPlan, { data, loading }] =
    useGetUserWeeklyWorkPlanLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
        handleToast(
          "알수없는 이유로 월별 근무 계획을 불러오지 못했습니다.",
          MessageTypes.ERROR
        );
      }
    });

  const [getUserWeeklyWorkPlanCount, { data: totalCount }] =
    useGetUserWeeklyWorkPlanCountLazyQuery({
      fetchPolicy: "no-cache",
      onError(error) {
        console.log(error);
      }
    });

  const fetchGetUserWeeklyWorkPlanCount = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getUserWeeklyWorkPlanCount({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [
    getUserWeeklyWorkPlanCount,
    selectedListOfEmployeeId,
    startDate,
    endDate
  ]);

  const fetchGetUserWeeklyWorkPlan = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getUserWeeklyWorkPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate,
          page: currentPage,
          take
        }
      });
    }
  }, [
    getUserWeeklyWorkPlan,
    selectedListOfEmployeeId,
    startDate,
    endDate,
    currentPage,
    take
  ]);

  useEffect(() => {
    if (
      prevEmployeeIdList.length !== selectedListOfEmployeeId.length ||
      prevEmployeeIdList.some(
        (id, index) => id !== selectedListOfEmployeeId[index]
      ) ||
      retryRender
    ) {
      prevEmployeeIdList = [...selectedListOfEmployeeId];
      fetchGetUserWeeklyWorkPlanCount();
      fetchGetUserWeeklyWorkPlan();
      if (retryRender) {
        setRetryRender(false);
      }
    }
  }, [
    selectedListOfEmployeeId,
    retryRender,
    fetchGetUserWeeklyWorkPlanCount,
    fetchGetUserWeeklyWorkPlan
  ]);

  const [getUserWeeklyWorkExcelPlan] = useGetUserWeeklyWorkExcelPlanLazyQuery({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 월별 근무 계획을 엑셀로 다운로드 하지 못했습니다.",
        MessageTypes.ERROR
      );
    },
    onCompleted(data) {
      if (
        data.getUserWeeklyWorkExcelPlan.ok &&
        data.getUserWeeklyWorkExcelPlan.excel
      ) {
        downloadFileFromServer(
          data.getUserWeeklyWorkExcelPlan.excel,
          `${moment().format(
            "YYYY-MM-DD-hh-mm-ss"
          )})_list_of_user_weekly_work.csv`
        );
      } else if (data.getUserWeeklyWorkExcelPlan.error) {
        handleToast(data.getUserWeeklyWorkExcelPlan.error, MessageTypes.ERROR);
      }
    }
  });

  const downloadExcel = useCallback(() => {
    if (selectedListOfEmployeeId.length > 0) {
      getUserWeeklyWorkExcelPlan({
        variables: {
          employeeIdList: selectedListOfEmployeeId,
          startDate,
          endDate
        }
      });
    }
  }, [getUserWeeklyWorkExcelPlan, selectedListOfEmployeeId]);

  const count: number = useMemo(() => {
    return totalCount?.getUserWeeklyWorkPlanCount.count ?? 0;
  }, [totalCount]);

  const list: UserWeeklyWorkPlanEntity[] = useMemo(() => {
    return data?.getUserWeeklyWorkPlan.list ?? [];
  }, [data]);

  const columns: ReactTable.Column<IUserWeeklyWorkPlanEntity>[] =
    useMemo(() => {
      const listOfColumn = Object.keys(COLUMN_FOR_VALUE);
      const smallWidth = 70;
      let newListOfColumn: ReactTable.Column<IUserWeeklyWorkPlanEntity>[] =
        listOfColumn.map((item, index) => {
          let width: number = 120;
          if (
            COLUMN_FOR_VALUE[item as WEEKLY_WORK_TYPE] ===
            COLUMN_FOR_VALUE.isCheck
          ) {
            width = smallWidth;
          }
          let sticky = "";
          if (sFixedColumnNumber) {
            if (index + 1 <= sFixedColumnNumber) {
              sticky = "left";
            }
          }

          return {
            Header(header) {
              if (
                COLUMN_FOR_VALUE[item as WEEKLY_WORK_TYPE] ===
                COLUMN_FOR_VALUE.isCheck
              ) {
                return (
                  <CheckBoxContainer>
                    <StyledInput
                      type="checkbox"
                      checked={header.isAllRowsSelected}
                      onChange={() => {
                        if (header.isAllRowsSelected) {
                          header.toggleAllRowsSelected(false);
                        } else {
                          header.toggleAllRowsSelected(true);
                        }
                      }}
                    />
                    <span>{COLUMN_FOR_VALUE[item as WEEKLY_WORK_TYPE]}</span>
                  </CheckBoxContainer>
                );
              }
              return COLUMN_FOR_VALUE[item as WEEKLY_WORK_TYPE];
            },
            accessor: item as WEEKLY_WORK_TYPE,
            Cell(cell: Cell<IUserWeeklyWorkPlanEntity>) {
              if (
                COLUMN_FOR_VALUE[item as WEEKLY_WORK_TYPE] ===
                COLUMN_FOR_VALUE.isCheck
              ) {
                return (
                  <StyledInput
                    type="checkbox"
                    checked={cell.row.isSelected}
                    onChange={event => {
                      event.stopPropagation();
                      if (cell.row.isSelected) {
                        cell.row.toggleRowSelected(false);
                      } else {
                        cell.row.toggleRowSelected(true);
                      }
                    }}
                  />
                );
              }
              return cell.value ?? "";
            },
            width,
            sticky
          };
        });
      return newListOfColumn;
    }, [sFixedColumnNumber]);

  const table = ReactTable.useTable<IUserWeeklyWorkPlanEntity>(
    {
      columns,
      data: list,
      state: {
        columnVisibility
      },
      onColumnVisibilityChange: setColumnVisibility
    },
    ReactTable.useBlockLayout,
    ReactTable.useRowSelect,
    ReactTable.useColumnOrder,
    useSticky
  );

  const selectedRow: ReactTable.Row<IUserWeeklyWorkPlanEntity> | undefined =
    useMemo(() => {
      if (table.selectedFlatRows.length > 0) {
        return table.selectedFlatRows[table.selectedFlatRows.length - 1];
      }
      return;
    }, [table.selectedFlatRows]);

  return (
    <Container $isVisibility={isVisibility}>
      <UserWeeklyWorkHeader
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        columns={table.columns as TColumn<IUserWeeklyWorkPlanEntity>[]}
        table={table}
        setIsSearch={saveSearchDate}
        title="주별근무"
        headerTitleList={Object.values(COLUMN_FOR_VALUE)}
        take={take}
        handleTake={handleTake}
        count={count}
        handleCurrentPage={handleCurrentPage}
        downloadExcel={downloadExcel}
        fixedColumnNumber={fixedColumnNumber}
        selectedFixedColumnNumber={selectedFixedColumnNumber}
        handleSelectedFCN={handleSelectedFCN}
      />
      <Suspense fallback={<Spinner />}>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <TableV2
                  table={table}
                  title="주별근무"
                  selectedRow={selectedRow}
                  height={height}
                  width={width}
                  loading={loading}
                  isCustomSelect
                />
              );
            }}
          </AutoSizer>
        </TableContainer>
      </Suspense>
      <TableFooter>
        <PageController
          currentPage={currentPage}
          totalPage={Math.ceil(count / take)}
          handleCurrentPage={handleCurrentPage}
        />
      </TableFooter>
      <ToastMessage
        message={message}
        isOpen={isToastMessageOpen}
        handleIsOpen={handleIsToastMessageOpen}
        messageTypes={toastMessageType}
      />
    </Container>
  );
}

export default UserWeeklyWork;
