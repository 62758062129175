import styled from "styled-components";
import AsonicDialog from "../../../../../components/asonic-dialog/asonic-dialog";
import FormRow from "../../../../../components/shared/form-row/form-row";
import StyledColumnContainer from "../../../../../components/shared/styled_column_container";
import StyledFlexContainer from "../../../../../components/shared/styled_flex_container";
import StyledInput from "../../../../../components/shared/styled_input";
import * as ReactTable from "react-table";
import { IUserDailyWorkPlanEntity } from "./user_daily_work";
import { useEffect, useMemo, useState } from "react";
import {
  useAddUserDailyWorkInfoMutation,
  useGetListOfOtTimeTypeCategoryLazyQuery
} from "../../../../../generated/graphql";
import { MessageTypes } from "../../../../../components/toast-message/toast-message";
import ButtonContainer from "../../../../../components/shared/button_container";
import StyledButton from "../../../../../components/shared/styled_button";
import moment from "moment";

interface IProps {
  selectedWorkInfo: ReactTable.Row<IUserDailyWorkPlanEntity>;
  handleOpenDialog: (value: boolean) => void;
  handleToast: (message: string, type: MessageTypes) => void;
}

const Container = styled(StyledColumnContainer)`
  justify-content: center;
  ${ButtonContainer} {
    justify-content: flex-end;
  }
`;

const ContentContainer = styled(StyledFlexContainer)`
  gap: 5px;
`;

// Spacer 컴포넌트 추가
const Spacer = styled.div<{ height?: string }>`
  height: ${props => props.height || "20px"};
`;

function EditUserDailyWorkPlanDialog({
  handleOpenDialog,
  selectedWorkInfo,
  handleToast
}: IProps) {
  const [date, setDate] = useState(
    selectedWorkInfo.original.startDate ?? moment().format("YYYY-MM-DD")
  );
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState("17:00");
  const [plusMinusMark, setPlusMinusMark] = useState<string>("+");
  const [breakTimeAdjustmentMin, setBreakTimeAdjustmentMin] = useState("0");
  const [categoryId, setCategoryId] = useState<string>(
    `${selectedWorkInfo.original.categoryId}` ?? "0"
  );

  // iss-1779 addUserDailyWorkInfo가 추가/수정 두개의 기능을 같이 한다
  const [addUserDailyWorkInfo, { client }] = useAddUserDailyWorkInfoMutation({
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onError(error) {
      console.log(error);
      handleToast(
        "알수없는 이유로 선택된 일별 근무 계획을 수정하지 못했습니다.",
        MessageTypes.ERROR
      );
      handleOpenDialog(false);
    },
    update(_, { data }) {
      if (data?.addUserDailyWorkInfo.ok) {
        handleToast(
          "성공적으로 선택된 일별 근무 계획을 수정 하였습니다",
          MessageTypes.SUCCESS
        );
        client.resetStore();
        handleOpenDialog(false);
      } else if (data?.addUserDailyWorkInfo.error) {
        handleToast(data?.addUserDailyWorkInfo.error, MessageTypes.ERROR);
      }
    }
  });

  const [getListOfOtTimeTypeCategory, { data: otTimeTypeCategoryData }] =
    useGetListOfOtTimeTypeCategoryLazyQuery({
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        if (data.getListOfOtTimeTypeCategory.error) {
          console.log(data.getListOfOtTimeTypeCategory.error);
        }
      }
    });

  useEffect(() => {
    getListOfOtTimeTypeCategory();
  }, [getListOfOtTimeTypeCategory]);

  const listOfCategory = useMemo(() => {
    const defaultCategory = { categoryId: 0, name: "미사용" };
    const categories =
      otTimeTypeCategoryData?.getListOfOtTimeTypeCategory.list ?? [];

    const mergedCategories = [defaultCategory, ...categories];
    return mergedCategories;
  }, [otTimeTypeCategoryData]);

  useEffect(() => {
    if (selectedWorkInfo?.original) {
      if (selectedWorkInfo?.original.breakTimeAdjustmentMin) {
        const newBreakTimeAdjustmentMin = parseInt(
          selectedWorkInfo?.original.breakTimeAdjustmentMin,
          10
        );
        if (newBreakTimeAdjustmentMin < 0) {
          setPlusMinusMark("-");
        }
        setBreakTimeAdjustmentMin(`${Math.abs(newBreakTimeAdjustmentMin)}`);
      }
    }
  }, [selectedWorkInfo]);

  return (
    <AsonicDialog
      title={"근무시간수정"}
      handleClose={(value: boolean) => {
        handleOpenDialog(value);
      }}
      width="650px"
      minWidth="650px"
      height="200px"
      minHeight="200px"
    >
      <Container>
        <FormRow title="출/퇴근 시간">
          <ContentContainer>
            <StyledInput
              type="date"
              value={date}
              onChange={event => setDate(event.currentTarget.value)}
            />
            <StyledInput
              type="time"
              value={startTime}
              onChange={event => setStartTime(event.currentTarget.value)}
            />
            <StyledInput
              type="time"
              value={endTime}
              onChange={event => setEndTime(event.currentTarget.value)}
            />
          </ContentContainer>
        </FormRow>
        <FormRow title="휴게시간(추가인정)">
          <ContentContainer>
            <select
              name="plus_or_minus_mark"
              id="plus_or_minus_mark"
              value={plusMinusMark}
              onChange={event =>
                setPlusMinusMark(event.currentTarget.value ?? "+")
              }
            >
              <option value={"+"}>{"+"}</option>
              <option value={"-"}>{"-"}</option>
            </select>
            <StyledInput
              type="number"
              value={breakTimeAdjustmentMin}
              onChange={event =>
                setBreakTimeAdjustmentMin(event.currentTarget.value)
              }
            />
          </ContentContainer>
        </FormRow>
        <FormRow title={"추가 인정 시간"}>
          <select
            name="categoryId"
            id="categoryId"
            onChange={event => {
              setCategoryId(event.target.value);
            }}
            value={categoryId}
          >
            {listOfCategory.map(category => (
              <option value={category.categoryId} key={category.categoryId}>
                {category.name}
              </option>
            ))}
          </select>
        </FormRow>
        <ButtonContainer>
          <StyledButton
            $maxWidth="50px"
            onClick={() => {
              if (selectedWorkInfo.original) {
                let newBreakTimeAdjustmentMin = breakTimeAdjustmentMin;
                if (plusMinusMark === "-") {
                  newBreakTimeAdjustmentMin = `${plusMinusMark}${breakTimeAdjustmentMin}`;
                }

                const payload = {
                  employeeId: selectedWorkInfo.original.employeeId as string,
                  startTime,
                  endTime,
                  date,
                  breakTimeAdjustmentMin: newBreakTimeAdjustmentMin,
                  categoryIdAdjustment: categoryId
                };
                addUserDailyWorkInfo({
                  variables: {
                    info: [payload]
                  }
                });
              }
            }}
          >{`수정`}</StyledButton>
        </ButtonContainer>
      </Container>
    </AsonicDialog>
  );
}

export default EditUserDailyWorkPlanDialog;
